import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPostById } from "../../api/posts";
import ContentLoader from "react-content-loader";
import { FaArrowLeft } from "react-icons/fa";
import "./single-post.css";
import Gradients from "../../shared/components/gradients/gradients";

function SinglePost() {
  const params = useParams();
  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loader, setLoader] = useState(false);
  const slug = params.slug as string;

  const excludeSlugRef = useRef("");
  const categorySlugRef = useRef("");
  const typeRef = useRef("");

  useEffect(() => {
    if (slug) {
      setLoader(true);
      getPostById(slug)
        .then((data) => {
          window.scrollTo(0, 0);
          setLoader(false);
          const elm = document.getElementById("single-post-id");
          if (elm != null) {
            elm.innerHTML = data.content || "";
            excludeSlugRef.current = data.slug || "";
            categorySlugRef.current = data.tags
              .split(",")
              .filter((item: string) => {
                typeRef.current = item;
                return false;
              })
              .join(",");
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [slug]);

  /* eslint-disable */
  if ((window as any)?.twttr!) {
    (window as any).twttr?.widgets?.load();
  }

  const contentLoader = () => {
    return (
      <ContentLoader height={630} width="100%">
        <rect x="0" y="30" rx="5" ry="5" width="100%" height="30px" />
        <rect x="0" y="80" rx="5" ry="5" width="100%" height="30px" />
        <rect x="0" y="150" rx="5" ry="5" width="60%" height="20px" />
        <circle cx="30" cy="250" r="24" />
        <rect x="60" y="235" rx="5" ry="5" width="80px" height="10px" />
        <rect x="60" y="255" rx="5" ry="5" width="80px" height="10px" />
        <circle cx="80%" cy="255" r="16" />
        <circle cx="88%" cy="255" r="16" />
        <circle cx="96%" cy="255" r="16" />
        <rect x="0" y="400" rx="5" ry="5" width="60%" height="14px" />
        <rect x="0" y="420" rx="5" ry="5" width="100%" height="14px" />
        <rect x="0" y="440" rx="5" ry="5" width="100%" height="14px" />
        <rect x="0" y="460" rx="5" ry="5" width="100%" height="14px" />
        <rect x="0" y="480" rx="5" ry="5" width="20%" height="14px" />
        <rect x="0" y="540" rx="5" ry="5" width="100%" height="14px" />
        <rect x="0" y="560" rx="5" ry="5" width="100%" height="14px" />
        <rect x="0" y="580" rx="5" ry="5" width="100%" height="14px" />
        <rect x="0" y="600" rx="5" ry="5" width="60%" height="14px" />
      </ContentLoader>
    );
  };

  return (
    <div className="flex justify-center items-center w-full flex-col bg-gradient-to-b from-[#f6fce8] to-white">
      <div className="absolute top-[60px] w-full h-[500px] bg-gradient-to-b from-[#e6f4ce] to-[#f6fce8] z-0" />

      <div className="z-10 bg-gradient-to-b from-white to-[#f6fce8] mt-[100px] py-7 px-4 md:p-16 rounded-[40px]">
        <div role="button" className="max-w-2xl mb-2 mx-auto x-50">
          <div className="w-[40px] h-[40px] ml-3 text-customDark rounded-full bg-customBase mb-8 padding-2 flex justify-center items-center">
            <FaArrowLeft
              onClick={() => {
                nav(-1);
              }}
              className="w-[21px] h-[18px] "
            />
          </div>
        </div>
        <div id="single-post-container">
          <div id="single-post-id" className="" />
        </div>
        {loader && <div className="max-w-2xl mx-auto ">{contentLoader()}</div>}
      </div>
    </div>
  );
}

export default SinglePost;
