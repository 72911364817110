import React, { useEffect } from "react";
import DarkStripes from "../../shared/components/stripes/dark-stripes";
import LightStripes from "../../shared/components/stripes/light-stripes";
import HeroItem from "../../shared/components/hero-item/hero-item";
import SubscribeForm from "../../shared/components/subscribe-form/subscribe-form";
import Dots from "../../shared/components/dots/dots";
import PastNews from "../../shared/components/past-news/past-news";
import useIsMobile from "../../hooks/use-is-mobile";
import { Link } from "react-router-dom";
import Gradients from "../../shared/components/gradients/gradients";

const Hero = () => {
  const isMobile = useIsMobile()
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <div className="w-full relative bg-gradient-to-b from-[#eef7df] to-[#F5F5F5] px-[20px] md:px-[62px] overflow-hidden">
      <Gradients />
      <div className="w-full flex flex-col mx-auto">
        <div className="w-[100%] md:w-[760px] mt-[42px]">
          <span className="text-neutral-900 text-[40px] md:text-[66px] font-nunito font-extrabold leading-[54px] md:leading-[74px]">
            Creators Just Don’t Want To Work Anymore... <br />
            in{" "}
          </span>
          <span className="text-lime-400 text-[40px] md:text-[66px] font-nunito font-extrabold leading-[54px] md:leading-[74px]">
            Web2
          </span>

          <div className="mt-[36px] text-neutral-900 text-lg md:text-2xl font-normal leading-[29.52px] md:leading-[41.28px] flex flex-col">
            <p>Weekly newsletter to help you better understand</p>
            <p>the shift of the creator economy</p>
          </div>
        </div>
        <SubscribeForm trailingText />
        <DarkStripes />
        <LightStripes />
        <div className="w-[460.16px] h-[244.12px] md:w-[600px] md:h-[365px] absolute right-[0] top-[500px] md:top-[651px] xl:top-[350px] md:shadow-lg z-10">
          <div className="Rectangle5 w-[410.16px] h-[224.12px] md:w-[688px] md:h-[365px] left-[140px] md:left-0 top-3 md:top-0 absolute bg-white bg-opacity-30 rounded-tl-[27px] rounded-tr-[27px] border border-white" />
          <img
            className="Rectangle7 w-[386.58px] h-[227.40px] md:w-[660px] md:h-[340px] left-[150px] md:left-[23px] top-[25px] md:top-[25px] absolute rounded-lg"
            alt="laptop"
            src="/assets/images/laptop.png"
          />
          <div className="Rectangle8 w-[386.58px] h-[10.70px] md:w-[640px] md:h-4 left-[150px] md:left-[23px] top-[25px] absolute bg-stone-200 rounded-lg border border-white" />
          <div className="Group1000011750 w-8 h-2 left-[155px] md:left-[28px] top-[28px] md:top-[29px] absolute">
            <div className="Ellipse77 w-[5.35px] h-[5.35px] md:w-2 md:h-2 left-0 top-0 absolute bg-orange-600 rounded-full" />
            <div className="Ellipse78 w-[5.35px] h-[5.35px] md:w-2 md:h-2 left-[8.03px] md:left-[12px] top-0 absolute bg-amber-400 rounded-full" />
            <div className="Ellipse79 w-[5.35px] h-[5.35px] md:w-2 md:h-2 left-[16.05px] md:left-[24px] top-0 absolute bg-lime-500 rounded-full" />
          </div>
        </div>

        <div className="Group1000011753 w-[100.99px] h-[212.02px] md:w-[570px] md:h-[404px] md:right-[100px] top-[480px] md:top-[651px] xl:top-[350px] absolute z-10">
          <div className="Group1000011751  w-[100.99px] h-[212.02px] md:w-[151px] md:h-[317px] left-0 top-[87px] absolute ">
            <img
              alt="phone"
              className="Rectangle6 w-[100.99px] h-[212.02px] md:w-[151px] shadow-lg md:h-[317px] left-0 top-0 absolute rounded-[20px] border-4 border-white bg-white backdrop-blur-[108px]"
              src="/assets/images/iphone.png"
            />
          </div>
        </div>
        <div className="bg-white rounded-[56px] relative flex flex-col p-[30px] md:p-[80px] mt-[400px] md:mt-[550px] xl:mt-[100px] w-full md:max-w-[1400px] xl:max-h-[1050px] self-center">
          {isMobile && (
            <img
              alt="icon"
              className="w-[250px] mx-auto mt-[-90px]"
              src="/assets/images/snowflake.svg"
            />
          )}

          <div className="md:flex md:flex-col-reverse xl:flex-none md:self-center xl:self-auto">
            <div className="Title mt-[31px] font-nunito md:mt-0 mx-auto md:mx-0 text-center md:text-start sd:w-[303px] md:w-[571px]">
              <span className="text-black text-[32px] md:text-[50px] font-bold md:font-semibold leading-10 md:leading-[62px]">
                Creator Economy meets{" "}
              </span>
              <span className="text-green-800 text-[32px] md:text-[50px] font-extrabold leading-10 md:leading-[62px]">
                Ai And Web3,
              </span>
              {!isMobile && (
                <span className="text-black text-[32px] md:text-[50px] font-extrabold leading-10 md:leading-[62px]">
                  {" "}
                  <br />
                </span>
              )}
              <span className="text-black text-[32px] md:text-[50px] font-bold md:font-semibold leading-10 md:leading-[62px]">
                Explained in Plain English
              </span>
            </div>

            {!isMobile && (
              <img
                alt="icon"
                className="max-w-[300px] md:mt-[-170px] xl:absolute xl:top-[170px] xl:right-[40px] md:self-center xl:self-auto"
                src="/assets/images/snowflake.svg"
              />
            )}
          </div>
          <div className="flex flex-col xl:flex-row justify-between gap-y-[16px] gap-x-[22px] md:grid md:grid-cols-2  xl:grid-cols-3 mt-[69px] xl:mt-[110px]">
            <HeroItem
              img="/assets/images/calendar.png"
              title="Current Events"
              text="Keep up with what’s going on for
              recording artists, screenwriters,
              and influencers"
            />

            <HeroItem
              img="/assets/images/trend_graph.png"
              title="Industry Trends"
              text="Stay on top of the latest trends to help you stay relevant"
            />

            <HeroItem
              img="/assets/images/blockchain.png"
              title="Emerging Technology"
              text="Learn about how AI and Web3 are disrupting the creator economy"
            />
          </div>
        </div>
        <div className="flex flex-col space-x-auto bg-customBlack w-screen ml-[-20px] md:ml-[-62px] mt-[80px] px-[20px] md:px-[52px] pt-[80px] pb-[24px] h-[800px]">
          <div className="flex flex-row justify-between items-end">
            <div className="font-nunito left-[200px] top-[1895px] md:mr-2 text-white text-[28px] md:text-[50px] font-semibold leading-[62px]">
              Past Newsletters
            </div>
            <Link to="/past-news" className="cursor-pointer">
              <div className="py-2 justify-center items-center gap-2 hidden md:inline-flex">
                <div className="ViewAll text-lime-400 text-xl font-semibold uppercase whitespace-nowrap mr-2">
                  View All
                </div>
                <img
                  alt="arrow"
                  className="arrowright w-5 h-5 relative origin-top-left"
                  src="assets/images/arrow_right.png"
                />
              </div>
            </Link>
          </div>
          <div className="mt-[20px] w-full h-[0px] border boder-[1px] border-lime-400" />
          <div className=" text-white text-base md:text-lg font-normal leading-[25.20px] mt-[30px] mb-[30px]">
            Preview our weekly newsletter before subscribing
          </div>

          <PastNews />

          <div className="py-2 justify-center items-center gap-2 mt-5 flex md:hidden">
            <Link
              to="/past-news"
              className="cursor-pointer justify-center items-center gap-2 flex"
            >
              <div className="ViewAll text-lime-400 text-xl font-semibold uppercase whitespace-nowrap">
                View All
              </div>
              <img
                alt="arrow"
                className="arrowright w-5 h-5 relative origin-top-left"
                src="assets/images/arrow_right.png"
              />
            </Link>
          </div>
        </div>
        <div className="relative py-[25px] md:py-[65px] px-[20px] md:px-[45px] w-[100%] md:w-[760px] h-[461px] bg-stone-100 bg-subBg mt-[-210px] self-center z-20 flex flex-row">
            <div className="flex flex-col">
              <img
                alt="mail"
                className="emailOutline w-[106px] h-[106px] relative"
                src="assets/images/email.png"
              />

              <div className="flex-col justify-center items-start gap-6 flex ">
                <div className="flex-col justify-center items-start gap-1 flex">
                  <div className="text-center font-nunito text-black text-[28px] md:text-[32px] font-semibold md:font-medium leading-[48px]">
                    Ready to get started?
                  </div>
                  <div className=" text-neutral-900 w-[285px] md:w-[100%] text-xs md:text-sm font-normal leading-tight md:leading-snug">
                    Weekly newsletter to help you better understand the shift of
                    the creator economy
                  </div>
                </div>
                <SubscribeForm />

                <div className=" text-stone-500 text-sm font-normal leading-[18px] text-center w-full">
                  Free. No noise, all signal. Unsubscribe anytime.
                </div>
              </div>
          </div>
        </div>
        <Dots />
        <div className="flex flex-col md:flex-row md:w-[80%] md:max-w-[1356px] h-[436px] md:h-[336px] bg-lime-400 relative justify-between items-center px-[50px] py-[60px] self-center mt-[75px]">
          <div className="Frame1000011829 mt-[100px] md:mt-[0px] w-screen md:w-[406px] h-[156px] flex-col justify-start md:items-start gap-6 inline-flex z-10">
            <div className="font-nunito text-center md:text-left text-black text-[28px] md:text-[44px] font-bold leading-9 md:leading-[51px]">
              Want to
              <br />
              advertise with us?
            </div>
            <div className="text-center text-neutral-900 text-lg font-normal leading-[29.52px] mb-[50px]">
              Let’s talk about how we can help grow your brand
            </div>
          </div>

          <div className="mt-[65px] xl:mt-[-65px] absolute left-[47%] sm:left-[48%] xl:left-1/2 transform -translate-x-1/2 xl:-translate-x-1/2">
            <div className="rotate-90 xl:rotate-180 justify-start items-start inline-flex">
              <div className="w-[42.33px] h-[42.33px] xl:w-[65.40px] xl:h-[65.40px] origin-top-left -rotate-45 border-4 border-white" />
              <div className="w-[42.33px] h-[42.33px] xl:w-[65.40px] xl:h-[65.40px] origin-top-left -rotate-45 border-4 border-white" />
              <div className="w-[42.33px] h-[42.33px] xl:w-[65.40px] xl:h-[65.40px] origin-top-left -rotate-45 border-2 border-white" />
              <div className="w-[42.33px] h-[42.33px] xl:w-[65.40px] xl:h-[65.40px] origin-top-left -rotate-45 border-2 border-white" />
              <div className="w-[42.33px] h-[42.33px] xl:w-[65.40px] xl:h-[65.40px] origin-top-left -rotate-45 border border-white" />
              <div className="w-[42.33px] h-[42.33px] xl:w-[65.40px] xl:h-[65.40px] origin-top-left -rotate-45 border border-white" />
            </div>
          </div>

          <div
            className="md:ml-[40px] w-[164px] md:w-[190px] h-14 left-0 bg-white rounded-[40px] text-center flex items-center justify-center cursor-pointer z-10 mt-4 md:mt-0"
            onClick={() => window.open("mailto:info@m6labs.co")}
          >
            <p className="text-neutral-900 text-base md:text-lg font-semibold uppercase">
              Contact us
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
