import React from 'react';

const Dots = () => {
    return (
      <>
        <div className="Group1000011760 w-[135px] h-[204.63px] left-[950px] xl:left-[1400px] top-[3400px] xl:top-[2450px] absolute z-5">
          <div className="Rectangle21549 w-4 h-4 left-[48px] top-[11.31px] absolute origin-top-left -rotate-45 bg-lime-400" />
          <div className="Rectangle21551 w-4 h-4 left-0 top-[193.31px] absolute origin-top-left -rotate-45 bg-lime-400" />
          <div className="Rectangle21550 w-[5.66px] h-[5.66px] left-[127px] top-[75px] absolute origin-top-left -rotate-45 bg-lime-400" />
        </div>
        <div className="Group1000011761 w-[92.31px] h-[255.69px] xl:left-[289.31px] top-[3400px] xl:top-[2450px] absolute z-5">
          <div className="Rectangle21547 w-4 h-4 left-[69.69px] top-[89px] absolute origin-top-left -rotate-45 bg-lime-400" />
          <div className="Rectangle21548 w-[5.66px] h-[5.66px] left-0 top-[4px] absolute origin-top-left -rotate-45 bg-lime-400" />
          <div className="Rectangle21552 w-[5.66px] h-[5.66px] left-[57.69px] top-[251.69px] absolute origin-top-left -rotate-45 bg-lime-400" />
        </div>
      </>
    );
};

export default Dots;