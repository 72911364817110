import React from 'react';
import { useNavigate } from 'react-router';

const Error = () => {
    const nav = useNavigate()
    return (
        <div className="Error w-full h-[961px] relative bg-gradient-to-b from-customLight to-white">
            <div className="Frame1000011893 flex flex-col justify-center items-center pt-[146px]">
                <div className="Frame w-[332px] h-[314px] relative mb-[60px]">
                    <img src="/assets/images/error_face_file.png" />
                    
                </div>
                <div className="Frame1000011829 flex-col justify-center items-center gap-5 flex">
                    <div className="Oops text-lime-400 text-[124px] font-black leading-[48px]">
                        OOPS!
                    </div>
                    <div className="WeCanTSeemToFindThePageYouReLookingFor text-center text-neutral-900 text-lg font-normal leading-loose pt-[20px]">
                        We can't seem to find the page you're looking for.
                    </div>
                </div>
                <button 
                    className="Frame1000011827 px-6 py-3.5 bg-lime-400 rounded-[32px] border-b border-lime-400 justify-center items-center gap-2 inline-flex mt-[62px]"
                    onClick={() => nav('/')}
                >
                    <div className="Arrow28 w-8 h-8 relative">
                        <img className="Group1 origin-top-left w-[32px] h-[32px] left-[5px] absolute" src="/assets/images/black_arrow_left.png"/>
                    </div>
                    <div className="BackToHomepage text-neutral-900 text-base font-semibold uppercase">
                        BACK TO HOMEPAGE
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Error