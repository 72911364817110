import React from "react";
import { type PastNewsProps } from "../../../interfaces/newsItem";
import { trimAndAddEllipsis } from "../../../helpers/trimAndAddEllipis";
import { useNavigate } from "react-router-dom";

const RecentNewsItem = ({ imgUrl, title, slug, date }: PastNewsProps) => {
  const nav = useNavigate();

  return (
    <div
      className="justify-center items-center gap-4 inline-flex cursor-pointer"
      onClick={() => {
        nav(`/p/${slug}`);
      }}
    >
      <img alt="img" className="w-[80px] h-20" src={imgUrl} />
      <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-neutral-900 text-base font-normal text-left">
          {trimAndAddEllipsis(title, 60)}
        </div>
        <div className="text-neutral-900 text-opacity-60 text-sm font-normal leading-none">
          {new Date(date).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
          })}
        </div>
      </div>
    </div>
  );
};

export default RecentNewsItem;
