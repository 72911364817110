import React, { ReactNode, useState } from 'react';
import Header from './header';
import Footer from './footer';
import SubscribeModal from '../subscribe-modal/subscribe-modal';

interface LayoutProps {
  children: ReactNode
}

const Layout = ({children}: LayoutProps) => {
    const [showModal, setShowModal] = useState(false)
    return (
      <div>
        <Header setShowModal={setShowModal}/>
        <div className='mt-[60px]'>{children}</div>
        <Footer setShowModal={setShowModal}/>
        {showModal ? <SubscribeModal setShowModal={setShowModal} /> : null}
      </div>
    );
};

export default Layout