import React from 'react';

const DarkStripes = () => {
    const StripeElement = () => {
        return (
          <div className="Title text-green-800 text-[34px] font-extrabold leading-[74px] whitespace-nowrap flex flex-row">

            Road2Web3{" "}
            <svg className="my-auto ml-[32px]" xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
              <path d="M7.47273 24.7072C6.50392 24.2149 5.89671 23.5596 5.65107 22.7414C5.44558 21.8756 5.631 20.9327 6.20731 19.9126L9.65411 13.6608L11.2729 15.5776L4.14319 16.6425C3.01063 16.8264 2.07141 16.6848 1.32553 16.2178C0.575949 15.707 0.156786 14.9255 0.0680398 13.8732C-0.0207062 12.8209 0.263489 12.0022 0.920625 11.4169C1.57406 10.7877 2.47446 10.4689 3.62181 10.4605L10.8347 10.382L9.63113 12.603L5.17484 6.88531C4.43213 5.93235 4.09141 5.03381 4.15269 4.18967C4.25411 3.29799 4.74485 2.57218 5.6249 2.01224C6.49756 1.36461 7.36706 1.20297 8.23338 1.52731C9.096 1.80781 9.81468 2.47577 10.3894 3.53121L13.5082 9.09679L12.3244 9.19663L14.5327 3.18177C14.8787 2.04868 15.4534 1.27163 16.2569 0.850622C17.1005 0.382068 18.0067 0.393953 18.9755 0.886277C19.9882 1.3749 20.5972 2.05211 20.8027 2.91791C21.0045 3.73985 20.7953 4.6627 20.1752 5.68644L16.7284 11.9382L15.1753 10.0159L22.2995 8.88523C23.4321 8.7014 24.3731 8.86489 25.1227 9.3757C25.8686 9.84267 26.2859 10.6023 26.3746 11.6545C26.4634 12.7068 26.181 13.5475 25.5276 14.1767C24.8705 14.762 23.9682 15.0588 22.8209 15.0673L15.6135 15.2115L16.8227 13.0563L21.2678 18.6424C22.0507 19.5479 22.4115 20.4226 22.3502 21.2668C22.2889 22.1109 21.8001 22.8586 20.8836 23.51C19.967 24.1613 19.0775 24.3467 18.2149 24.0662C17.3924 23.7382 16.6956 23.0683 16.1246 22.0568L12.929 16.3652L14.1128 16.2653L11.8498 22.4173C11.4562 23.5102 10.8596 24.2891 10.0598 24.754C9.30019 25.1713 8.43783 25.1557 7.47273 24.7072Z" fill="#3C6B3B"/>
            </svg>
          </div>
        );
    }

    return (
      <div className="px-8 py-3 left-[-445.94px] top-[660px] md:top-[725.21px] absolute origin-top-left rotate-[-10.82deg] md:rotate-[-4.82deg] bg-lime-400 justify-center items-center gap-8 inline-flex">
        {[...Array(20)].map((_, index) => (
          <StripeElement key={index} />
        ))}
      </div>
    );
};

export default DarkStripes;