import React from 'react';
import { trimAndAddEllipsis } from '../../../helpers/trimAndAddEllipis';
import { useNavigate } from 'react-router-dom';

interface PastNewsItemProps {
    img: string
    title: string
    date: string
    slug: string
}

const PastNewsItem = ({img, title, date, slug}: PastNewsItemProps) => {
 const nav = useNavigate();

    return (
      <div
        className="grid grid-cols-2 w-[450px] h-[220px] cursor-pointer bg-customDark"
        onClick={() => {
          nav(`/p/${slug}`);
        }}
      >
        <img alt="img" className="w-[200px] md:w-full h-[250px] object-cover" src={img} />
        <div className="bg-customDark py-4 pr-4 pl-0 md:p-4 mb-6 flex flex-col justify-between">
          <div className="px-2 py-1 bg-customBase text-black rounded-lg flex items-center justify-center w-[120px]">
            {new Date(date).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
            })}
          </div>
          <p className="text-white text-[24px] mb-3">
            {" "}
            {trimAndAddEllipsis(title, 40)}
          </p>
          <p className="text-white text-base">Read now</p>
        </div>
      </div>
    );
};

export default PastNewsItem;