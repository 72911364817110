import axios from 'axios'
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export const getPosts = async (amount?: number): Promise<any> => {
  const config = {
    method: 'get',
    url: `${apiBaseUrl}/api/posts`,
    params: {
      type: 'news',
      limit: amount ?? 100
    }
  }
  return await new Promise((resolve, reject) => {
    axios(config)
      .then((res) => { resolve(res.data) })
      .catch((err) => { reject(err) })
  })
}

export const getPostById = async (slug: string): Promise<any> => {
  const config = {
    method: 'get',
    url: `${apiBaseUrl}/api/posts/${slug}`
  }
  return await new Promise((resolve, reject) => {
    axios(config)
      .then((res) => { resolve(res.data) })
      .catch((err) => { reject(err) })
  })
}
