import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Layout from './shared/components/layout/layout';
import Hero from './pages/hero/hero';
import SinglePost from './pages/single-post/single-post';
import AllPosts from './pages/all-posts/all-posts';
import Error from "./pages/error/error";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Hero />}></Route>
          <Route path="/p/:slug" element={<SinglePost />}></Route>
          <Route path="/past-news" element={<AllPosts />}></Route>
          <Route path="*" element={<Error />}></Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
