import React from 'react';
import RecentNews from '../recent-news/recent-news';
import { Link } from 'react-router-dom';

type FooterPropsT = {
  setShowModal: (arg: boolean) => void
}
const Footer = (props: FooterPropsT) => {
  const {setShowModal} = props
    return (
      <div className="flex flex-col md:flex-row w-full mt-[80px] md:mt-[120px] mb-[30px] md:mb-[120px] bg-white px-[20px] md:px-0">
        <div className="flex flex-col items-start md:items-center md:w-[40%] min-w-[200px]">
          <img
            alt="logo"
            src="/assets/images/logo-header.svg"
            className="h-[56px]"
          />
        </div>
        <div className="w-[40%] flex flex-col min-w-[320px]">
          <p className="font-semibold text-2xl mb-[20px] mt-[40px] md:mt-0">
            Recent Newsletters
          </p>

          <RecentNews />

          <Link
            className="border-b border-customBase p-2 w-[100px] uppercase font-bold mt-[20px] cursor-pointer"
            to="/past-news"
          >
            View All
          </Link>
        </div>

        <div className="w-[20%] flex flex-col min-w-[150px] gap-y-4">
          <p className="font-semibold text-2xl mt-[40px] md:mt-0 md:mb-[20px]">
            Quick Links
          </p>

          <Link className="text-[18px] cursor-pointer" to="/">
            Home
          </Link>
          <Link className="text-[18px] cursor-pointer" to="/past-news">
            Past Newsletters
          </Link>
          <Link
            className="text-[18px] cursor-pointer"
            to="https://o7fat38478c.typeform.com/to/BToKDy0q?utm_source=www.m6labs.co&utm_medium=newsletter&utm_campaign=sec-given-green-light-to-appeal"
          >
            Partner with Us
          </Link>
          <p
            className="text-[18px] cursor-pointer"
            onClick={() => setShowModal(true)}
          >
            Subscribe
          </p>
        </div>
      </div>
    );
};

export default Footer