import React from "react";

const LightStripes = () => {
 const StripeElement = () => {
   return (
     <div className="Title text-lime-400 text-[34px] font-extrabold leading-[74px] whitespace-nowrap flex flex-row">
        Road2Web3{" "} 
        <svg className="my-auto ml-[32px]" xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26" fill="none">
          <path d="M5.76873 23.1892C4.92889 22.4995 4.47727 21.7287 4.41386 20.8768C4.39992 19.987 4.7843 19.1063 5.56703 18.2345L10.2809 12.8731L11.4483 15.0938L4.25666 14.5962C3.1111 14.5315 2.22451 14.1907 1.59688 13.5739C0.975089 12.9135 0.734324 12.0599 0.874583 11.0133C1.01484 9.96662 1.46892 9.22839 2.23681 8.79858C3.01055 8.32515 3.9585 8.20803 5.08068 8.44719L12.1408 9.92603L10.4866 11.8352L7.36815 5.29105C6.84841 4.20036 6.70947 3.24948 6.95134 2.43842C7.24267 1.58959 7.87838 0.986683 8.85848 0.629699C9.85026 0.185493 10.7342 0.215155 11.5102 0.718685C12.292 1.17861 12.8497 1.98583 13.1833 3.14037L15.0285 9.24756L13.851 9.08977L17.3045 3.69264C17.8867 2.66083 18.6155 2.026 19.4908 1.78816C20.4156 1.51255 21.2979 1.71958 22.1378 2.40923C23.0212 3.10474 23.4699 3.89736 23.4839 4.78709C23.5037 5.63322 23.1004 6.48924 22.2741 7.35516L17.5602 12.7166L16.4582 10.5046L23.6586 10.9368C24.8042 11.0015 25.6878 11.3641 26.3096 12.0245C26.9373 12.6414 27.1809 13.4731 27.0407 14.5197C26.9004 15.5664 26.4434 16.3264 25.6697 16.7998C24.9018 17.2297 23.9568 17.325 22.8346 17.0858L15.7657 15.6724L17.4112 13.8286L20.5471 20.242C21.1163 21.2949 21.28 22.2269 21.0381 23.0379C20.7962 23.849 20.1576 24.4737 19.1222 24.9121C18.0868 25.3504 17.1782 25.3397 16.3963 24.8797C15.664 24.3821 15.128 23.5777 14.7886 22.4668L12.8955 16.22L14.073 16.3778L10.5366 21.897C9.91663 22.8794 9.16605 23.5113 8.28486 23.7927C7.45312 24.0364 6.61441 23.8352 5.76873 23.1892Z" fill="#B2E644"/>
        </svg>
     </div>
   );
 };

  return (
    <div className="px-8 py-1 md:py-2 left-[-431.98px] top-[1360px] md:top-[1420px] absolute origin-top-left rotate-[12deg] md:rotate-[8deg] bg-neutral-900 border border-stone-200 justify-center items-center gap-8 inline-flex">
      {[...Array(20)].map((_, index) => (
        <StripeElement key={index} />
      ))}
    </div>
  );
};

export default LightStripes;
