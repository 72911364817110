import React from 'react';
import { trimAndAddEllipsis } from '../../../helpers/trimAndAddEllipis';
import { useNavigate } from 'react-router-dom';

interface AllPostsItemProps {
    date: string
    title: string
    img: string
    slug: string
}

const AllPostsItem = ({date, title, img, slug}: AllPostsItemProps) => {
 const nav = useNavigate();

    return (
      <div
        className="flex flex-col md:flex-row justify-between bg-customDark w-full md:h-[240px] cursor-pointer"
        onClick={() => {
          nav(`/p/${slug}`);
        }}
      >
        <img
          src={img}
          alt="img"
          className="w-[320px] h-[240px] flex md:hidden"
        />
        <div className="p-6 flex flex-col gap-y-4">
          <p className="text-[#f5f5f5] text-[18px]">
            {" "}
            {new Date(date).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
            })}
          </p>
          <h2 className="text-[22px] font-nunito md:text-[28px] font-bold text-white mt-2 md:mt-4">
            {" "}
            {trimAndAddEllipsis(title, 60)}
          </h2>
        </div>
        <img
          src={img}
          alt="img"
          className="w-[320px] h-[240px] hidden md:flex"
        />
      </div>
    );
};

export default AllPostsItem;