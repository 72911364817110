import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

interface MobileNavProps {
  isOpen: boolean;
  setIsMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: (arg: boolean) => void;
}

const MobileNav = ({
  isOpen,
  setIsMobileOpen,
  setShowModal,
}: MobileNavProps) => {
  const navHandler = () => {
    setIsMobileOpen(false);
  };

  const location = useLocation();

  return (
    <>
      <div
        className={`fixed w-screen inset-0 bg-neutral-900 bg-opacity-90 transition-opacity z-40 ${
          !isOpen && "hidden"
        }`}
      />
      <div
        className={`h-full pb-[50px] left-[-35px] top-[0] fixed bg-white flex flex-col justify-between transition-all z-50 overflow-hidden" ${
          isOpen ? "w-[270px]" : "w-0 hidden"
        }`}
      >
        <div className="flex mt-4 px-4 w-[270px] justify-between items-center fixed">
          <div className="w-[88px] h-[43.26px] left-0 top-0">
            <img alt="logo" src="/assets/images/logo-header.svg" />
          </div>

          <MdOutlineClose
            className="text-neutral-900 text-[20px]"
            onClick={() => {
              setIsMobileOpen(false);
            }}
          />
        </div>
        <div className="mt-[100px] py-4 px-4 justify-start items-center gap-4 flex flex-col text-neutral-900 text-lg text-left font-normal bg-gradient-to-r from-homergba to-white">
          <Link
            onClick={() => navHandler()}
            className={`w-full h-[40px] bg-white p-2 flex items-center text-black ${
              location.pathname === "/" &&
              "font-semibold bg-gradient-to-r from-customBase to-white rounded-2xl"
            }`}
            to="/"
          >
            <p>Home</p>
          </Link>
          <Link
            onClick={() => navHandler()}
            className={`w-full h-[40px] p-2 text-black ${
              location.pathname === "/past-news" &&
              "font-semibold bg-gradient-to-r from-customBase to-white rounded-2xl"
            }`}
            to="/past-news"
          >
            Past Newsletters
          </Link>
          <Link
            onClick={() => navHandler()}
            className="Link text-neutral-900 text-lg p-2 font-normal w-full h-[40px]"
            to="https://o7fat38478c.typeform.com/to/BToKDy0q?utm_source=www.m6labs.co&utm_medium=newsletter&utm_campaign=sec-given-green-light-to-appeal"
          >
            Partner with us
          </Link>
          <div className="mt-[15px] ml-[-105px] px-6 py-2 bg-customBase rounded-[20px] justify-start items-start flex">
            <button
              className="Text text-center text-white text-base font-medium leading-normal"
              onClick={() => {
                setIsMobileOpen(false);
                setShowModal(true);
              }}
            >
              Join Free
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
