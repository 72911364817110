import React, { useEffect, useState } from "react";
import { getPosts } from "../../../api/posts";
import PastNewsItem from "./past-news-item";
import { type PostProps } from "../../../interfaces/post";


const PastNews = () => {
  const [posts, setPosts] = useState<PostProps[] | []>([]);

  const fetchPosts = () => {
    getPosts(5)
      .then((posts) => {
        setPosts(posts.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="flex flex-row gap-x-7 mt-[20px] overflow-x-scroll overflow-y-hidden no-scrollbar h-[220px]">
      {posts.length > 0 ? (
        posts.map((element) => (
          <div
            key={element.title}
          >
            <PastNewsItem
              img={element.thumbnailUrl}
              title={element.title}
              slug={element.slug}
              date={element.publishDate}
            />
          </div>
        ))
      ) : (
        <div className="text-center uppercase">No past news...</div>
      )}
    </div>
  );
};

export default PastNews;