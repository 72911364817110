import React from 'react'
import SubscribeForm from '../subscribe-form/subscribe-form';

interface SubscribeModalPropsT {
  setShowModal: (arg1: boolean) => void
}
const SubscribeModal = (props: SubscribeModalPropsT): JSX.Element => {
  const { setShowModal } = props

  return (
        <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={() => { setShowModal(false) }}>
            {/* <!--
            Background backdrop, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
            --> */}
            <div className="fixed inset-0 bg-neutral-900 bg-opacity-90 transition-opacity"></div>

            <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    {/* <!--
                        Modal panel, show/hide based on modal state.

                        Entering: "ease-out duration-300"
                        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        To: "opacity-100 translate-y-0 sm:scale-100"
                        Leaving: "ease-in duration-200"
                        From: "opacity-100 translate-y-0 sm:scale-100"
                        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    --> */}
                    <div className="modalContainer flex flex-col md:flex-row w-[350px] md:w-[993px] h-[668px] md:h-[100%] relative transform overflow-hidden bg-white shadow-xl transition-all items-center md:items-start" onClick={(e) => e.stopPropagation()}>
                        <div className="imageContainer w-[350px] h-[260px] md:w-[451px] md:h-[589px] relative">
                            <img 
                                className="Bg w-[350px] h-[260px] md:w-[451px] md:h-[589px] md:left-0 md:top-0 absolute" 
                                src="/assets/images/subscribe_modal_image.png" 
                            />
                        </div>
                        <img 
                            className="Email2 w-[92px] h-[92px] md:w-[124px] md:h-[124px] md:left-[507px] left-[129px] top-[270px] md:top-[72px] absolute"
                            src="/assets/images/subscribe_modal_email.png"
                        />
                        <div className="container md:w-[422px] md:h-[154px] md:left-[507px] top-[370px] md:top-[306px] absolute flex-col justify-center items-center md:items-start gap-2.5 inline-flex">
                            <div className="KeepUpWithTheFutureOfTheCreatorEconomy w-[310px] md:w-[422px] text-neutral-900 text-2xl md:text-4xl font-bold leading-9 md:leading-[44px] text-center md:text-left">
                                Keep up with the future of the creator economy
                            </div>
                            <div className="Subtitle w-[281px] md:w-[422px] text-neutral-900 text-sm md:text-base font-normal leading-normal md:leading-7 text-center md:text-left">
                                Weekly newsletter to help you better understand the shift of the creator economy
                            </div>
                            <SubscribeForm trailingText setShowModal={setShowModal}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeModal
