import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from 'react-router-dom'
import MobileNav from "./mobileNav";

type HeaderPropsT = {
  setShowModal: (arg: boolean) => void
}
const Header = (props: HeaderPropsT) => {
  const {setShowModal} = props
 const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  return (
    <div className="absolute top-0 w-full bg-white flex justify-between items-center h-[60px] py-2 px-3 md:px-6 ">
      <Link className="cursor-pointer" to="/">
        <img
          alt="logo"
          src="/assets/images/logo-header.svg"
          className="h-[40px] ml-[16px]"
        />
      </Link>

      <div className="flex flex-row space-x-8 mr-4 items-center text-black text-[16px] leading-[25.20px]">
        <Link className="cursor-pointer hidden md:flex" to="/past-news">
          Past Newsletter
        </Link>
        <Link
          className="text-black text-lg leading-[25.20px] cursor-pointer hidden md:flex"
          to="https://o7fat38478c.typeform.com/to/BToKDy0q?utm_source=www.m6labs.co&utm_medium=newsletter&utm_campaign=sec-given-green-light-to-appeal"
        >
          Partner with us
        </Link>
        <button
          className=" bg-customBase py-[6px] px-5 rounded-full hidden md:flex"
          onClick={() => setShowModal(true)}
        >
          Join Free
        </button>

        <FaBars
          className="text-[24px] md:hidden cursor-pointer"
          onClick={() => {
            setIsMobileModalOpen(true);
          }}
        />

        <MobileNav
          isOpen={isMobileModalOpen}
          setIsMobileOpen={setIsMobileModalOpen}
          setShowModal={setShowModal}
        />
      </div>
    </div>
  );
};

export default Header;
