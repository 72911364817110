import React from 'react';

const Gradients = () => {
    return (
      <>
        <img
          src="/assets/images/gradient.svg"
          alt=""
          className="absolute top-0 left-0 w-[120px]"
        />
        <img
          src="/assets/images/gradient.svg"
          alt=""
          className="absolute top-0 right-0 w-[120px]"
          style={{
            transform: "scale(-1, 1)",
          }}
        />
      </>
    );
};

export default Gradients