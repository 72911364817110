import React from 'react';

interface HeroItemProps {
    img: string
    title: string
    text: string 
}

const HeroItem = ({ img, title, text }: HeroItemProps) => {
  return (
    <div className="bg-stone-100 px-2 rounded-[32px] flex flex-col items-center w-[100%] sd:w-[80%] h-[390px] md:h-[439px] min-w-[200px]">
      <div className="h-[200px]">
        <img
          alt="icon"
          className="Group w-[80px] mt-[30px] md:mt-[50px] mb-[55px] md:mb-[75px]"
          src={img}
        />
      </div>
      <div className="flex-col justify-center items-center gap-[18px] inline-flex">
        <div className="Title text-black text-[28px] font-bold leading-[37.80px] text-center font-nunito">
          {title}
        </div>
        <div className="Frame1000011850 flex-col justify-start items-start gap-3 flex">
          <div className="Subtitle text-center text-neutral-900 text-lg md:text-xl font-normal leading-7 md:leading-[31.20px] mb-[20px]">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroItem