import React, { useEffect, useState } from 'react'
import { PostProps } from '../../interfaces/post'
import useIsMobile from '../../hooks/use-is-mobile'
import { useNavigate } from 'react-router-dom'
import { getPosts } from '../../api/posts';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import AllPostsItem from '../../shared/components/all-posts-item/all-posts-item';
import Gradients from '../../shared/components/gradients/gradients';


interface PageIndexContainerProps {
  children: React.ReactNode;
}

const PageIndexContainer = ({ children }: PageIndexContainerProps) => {
  return (
    <div
      className={`p-2 w-[30px] md:w-[40px] h-[30px] md:h-[40px] flex items-center justify-center mr-2 cursor-pointer border rounded-full border-[#F1F1F1]
      }`}
    >
      {children}
    </div>
  );
};

const AllPosts = () => {
  const [posts, setPosts] = useState<PostProps[] | []>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const isMobile = useIsMobile()
  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const totalPages: number = Math.ceil(posts.length / 5)

  const showItems = (page: number): PostProps[] => {
    const startIndex: number = (page - 1) * 5
    const endIndex: number = startIndex + 5
    return posts.slice(startIndex, endIndex)
  }

  const displayedItems: PostProps[] = showItems(currentPage)

  const handlePageClick = (page: number) => {
    setCurrentPage(page)
  }

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 4;
    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPageNumber(i));
      }
    } else {
      if (currentPage <= maxVisiblePages - 1) {
        for (let i = 1; i <= maxVisiblePages; i++) {
          pageNumbers.push(renderPageNumber(i));
        }
        pageNumbers.push(<span key="ellipsis">...</span>);
        pageNumbers.push(renderPageNumber(totalPages));
      } else if (currentPage >= totalPages - (maxVisiblePages - 2)) {
        pageNumbers.push(renderPageNumber(1));
        pageNumbers.push(<span key="ellipsis">...</span>);
        for (let i = totalPages - (maxVisiblePages - 2); i <= totalPages; i++) {
          pageNumbers.push(renderPageNumber(i));
        }
      } else {
        pageNumbers.push(renderPageNumber(1));
        pageNumbers.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(renderPageNumber(i));
        }
        pageNumbers.push(<span key="ellipsis">...</span>);
        pageNumbers.push(renderPageNumber(totalPages));
      }
    }

    return pageNumbers;
  }

  const renderPageNumber = (pageNumber: number) => (
    <span
      key={pageNumber}
      className={`p-2 w-[30px] md:w-[40px] h-[30px] md:h-[40px] flex items-center justify-center mr-2 ${
        currentPage === pageNumber
          ? "rounded-lg text-customBlack bg-customBase"
          : "border rounded-full border-[#F1F1F1] cursor-pointer"
      }`}
      onClick={() => {
        handlePageClick(pageNumber);
        window.scrollTo(0, 0);
      }}
    >
      {pageNumber}
    </span>
  );

  const fetchPosts = () => {
    getPosts(50)
      .then((posts) => {
        setPosts(posts.data || []);
        console.log(JSON.stringify(posts.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

    return (
      <div className="w-full relative bg-gradient-to-b from-[#f6fce8] via-white to-white">
        <Gradients />
        <div className="flex flex-row justify-between px-[20px] md:px-[92px] items-center h-[300px] bg-gradient-to-b from-customLight to-[#f5f5f5]">
          <div className="flex flex-col">
            <div className="text-neutral-900 font-nunito text-[32px] md:text-[66px] font-bold leading-9 md:leading-[74px]">
              Past Newsletters
            </div>
            <div className=" text-neutral-900 text-sm md:text-2xl font-normal max-w-[550px] leading-snug md:leading-[37.44px] mt-1">
              Get a glimpse of our newsletter's invaluable insights before
              subscribing
            </div>
          </div>
          <div className="flex flex-row">
            <div className="w-[73.35px] h-[45.59px]  md:w-[184px] md:h-[100%] relative">
              <img
                className="w-[73.35px] h-[45.59px] md:w-[184.30px] md:h-[116px] left-[-0.30px] top-0 flex mt-[20px]"
                src="/assets/images/past_newsletters_email.png"
              />
            </div>
            <div className=" w-[67px] h-[102px] md:w-[135px] md:h-[204.63px] relative right-[50px] ">
              <div className="Rectangle21549 w-[6.40px] h-[6.40px] md:w-4 md:h-4 left-[60px] md:left-[48px] top-[0px] absolute origin-top-left -rotate-45 bg-lime-400" />
              <div className="Rectangle21551 w-[6.40px] h-[6.40px] md:w-4 md:h-4 left-[40px] md:left-0 md:right-[40px] top-[90px] md:top-[183.31px] absolute origin-top-left -rotate-45 bg-neutral-900" />
              <div className="Rectangle21550 w-[2.26px] h-[2.26px] md:w-[5.66px] md:h-[5.66px] left-[100px] md:left-[127px] top-[50px] absolute origin-top-left -rotate-45 bg-neutral-900" />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-[80%] min-w-[320px] max-w-[1000px] mx-auto gap-y-8 mt-[80px] md:mt-[120px]">
          {posts.length > 0 ? (
            displayedItems.map((element) => (
              <div key={element.title}>
                <AllPostsItem
                  img={element.thumbnailUrl}
                  title={element.title}
                  date={element.publishDate}
                  slug={element.slug}
                />
              </div>
            ))
          ) : (
            <div className="text-center uppercase font-inter">
              No past news...
            </div>
          )}
        </div>

        <div className="flex justify-center text-black text-[16px] w-full my-4 mt-8">
          <div
            onClick={() => {
              setCurrentPage(1);
              window.scrollTo(0, 0);
            }}
          >
            <PageIndexContainer>
              <AiOutlineDoubleLeft />
            </PageIndexContainer>
          </div>
          <div
            onClick={() => {
              setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
              window.scrollTo(0, 0);
            }}
          >
            <PageIndexContainer>
              <IoIosArrowBack />
            </PageIndexContainer>
          </div>
          {getPageNumbers()}
          <div
            onClick={() => {
              setCurrentPage(
                currentPage < totalPages ? currentPage + 1 : totalPages
              );
              window.scrollTo(0, 0);
            }}
          >
            <PageIndexContainer>
              <IoIosArrowForward />
            </PageIndexContainer>
          </div>
          <div
            onClick={() => {
              setCurrentPage(totalPages);
              window.scrollTo(0, 0);
            }}
            className="mr-2 md:mr-0"
          >
            <PageIndexContainer>
              <AiOutlineDoubleRight />
            </PageIndexContainer>
          </div>
        </div>
      </div>
    );
};

export default AllPosts