import React, { useEffect, useState } from "react";
import RecentNewsItem from "./recent-news-item";
import { type PostProps } from "../../../interfaces/post";
import { getPosts } from "../../../api/posts";

const RecentNews = () => {
  const [posts, setPosts] = useState<PostProps[] | []>([]);

  const fetchPosts = () => {
    getPosts(3)
      .then((posts) => {
        setPosts(posts.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="Frame1574 flex-col justify-start items-start gap-6 flex">
      {posts.length > 0 ? (
        posts.map((element) => (
          <RecentNewsItem
            key={element.title}
            imgUrl={element.thumbnailUrl}
            title={element.title}
            slug={element.slug}
            date={element.publishDate}
          />
        ))
      ) : (
        <div className="text-center uppercase">No recent news...</div>
      )}
    </div>
  );
};

export default RecentNews;
