import React, { useEffect, useRef, useState } from "react";
import useIsMobile from "../../../hooks/use-is-mobile";
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

interface SubscribeFormProps {
  trailingText?: boolean;
  setShowModal?: (arg: boolean) => void;
}

const SubscribeForm = ({ trailingText, setShowModal }: SubscribeFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState(false);
  const [hasInvalidEmail, setHasInvalidEmail] = useState(false);
  const [emailInput, setEmailInput] = useState("What’s your email?");
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile();

  const successMessage = (
    <div className="flex flex-row">
      <img
        src="/assets/images/successful.png"
        className="SuccessfulIcon w-[92px] h-[51.20px] md:w-[106px] md:h-[59px]"
      />
      <div className="SubscribedSuccessfully flex text-neutral-900 text-xs md:text-base font-medium justify-center items-center">
        {isMobile ? "Well done" : "Subscribed successfully"}
      </div>
    </div>
  );

  const failedMessage = (
    <div className="flex flex-row">
      <img
        src="/assets/images/failed.png"
        className="FailedIcon w-[45px] md:w-14 h-[45px] md:h-14"
      />
      <div className="PleaseEnterAValidEmail flex text-red-600 text-xs md:text-base font-medium justify-center items-center">
        Please enter a valid email
      </div>
    </div>
  );

  const defaultMessage = (
    <div className="Subtitle mt-[18px] md:mt-[31px] md:w-[460px] text-center text-stone-500 text-xs md:text-sm font-normal leading-[18px]">
      Free. No noise, all signal. Unsubscribe anytime.
    </div>
  );

  const subscribeToPublication = async () => {
    if (emailInput.length > 4) {
      try {
        setIsLoading(true);
        const response = await axios.post(`${apiBaseUrl}/api/subscribe`, {
          email: emailInput,
        });

        // console.log(response.data)
        setIsLoading(false);
        setHasSubscribed(true);
        if (typeof setShowModal === "function") {
          setShowModal(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  }, [emailInput]);

  return (
    <>
      <div className="w-auto h-14 realtive flex mt-[27px]">
        <div className="w-[210px] md:w-[320px] px-7 py-[18px] bg-lime-400 rounded-[32px] justify-center items-center gap-2 inline-flex">
          <input
            className="md:w-80 bg-lime-400 text-neutral-700 text-sm font-medium leading-tight outline-none"
            ref={inputRef}
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            onClick={() => setEmailInput("")}
          />
        </div>
        <div className="w-[140px] md:w-[180px] pl-[35px] md:pl-[50px] pr-7 py-[18px] ml-[-48px] bg-white rounded-[32px] border border-lime-400 justify-center items-center gap-2 inline-flex cursor-pointer">
          <div
            className="text-neutral-700 text-base font-bold uppercase leading-tight cursor-pointer"
            onClick={() => subscribeToPublication()}
          >
            Subscribe
          </div>
        </div>
      </div>
      {trailingText && (
        <>
          {hasSubscribed && typeof setShowModal === "function"
            ? successMessage
            : hasInvalidEmail && typeof setShowModal === "function"
            ? failedMessage
            : defaultMessage}
        </>
      )}
    </>
  );
};

export default SubscribeForm;
